<template lang="html">
  <div class="header">


    <div class="group">
      <label for="companys">Kompanija: </label>
      <select name="companys" id="type" v-model="type" @change="onChangeCompany($event)">
        <option v-for="company in companys" v-bind:key="company.name">{{ company.name }}</option>
      </select>
    </div>
    <div class="group">
      <label for="user">Zaposleni: </label>
      <select name="user" id="type" v-model="client" @change="onChangeUser($event)">
        <option v-for="user in usersSameCompany" v-bind:key="user.name">{{ user.name }}</option>
      </select>
    </div>

  </div>

  <div class="LicniKpi">
    <input class="mt-2 w-25" v-model="licniKpiTitle" />

    <div class="validation">
      {{ validation }}
    </div>

    <div class="kpi" v-for="(licni, i) in licniKpi" v-bind:key="i">
      <div class="" style="width:100%;">
        <label for="">Naziv:</label>
        <input class="form-control" type="text" v-model="licni.name">
      </div>

      <div class="" style="width:100%;">
        <label for="">Ponder:</label>
        <input class="form-control" type="number" v-model="licni.ponder">
      </div>

      <label for="">Definicija:</label>
      <!-- <input
              class="form-control"
              type="text"
              v-model="licni.comment"
              > -->

      <textarea class="form-control" rows="10" style="width:100%;" v-model="licni.comment" v-bind:key="i"></textarea>
      <button type="button" name="button" @click="ukloniLicni(i)">ukloni</button>
    </div>
    <button type="button" name="button" @click="increaseLicni">Dodaj Kpi</button>

  </div>

  <button v-if="licniKpi.length > 0" type="button" name="button" @click="submit">Sačuvaj promenu</button>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      nmb: 0,
      licniKpi: [],
      currentCompany: "",
      currentUser: "",
      validation: "",
      licniProc: 0,
      lastEval: 0,
      evalEmpty: true,
      type: null,
      client: null,
      licniKpiTitle: "Licni KPI"
    }
  },
  computed: mapGetters(['companys', 'users', 'usersSameCompany']),
  methods: {
    ...mapActions(['getCompanies', 'setLicniKpi', 'getAllUsers', 'getCollegues']),
    onChangeCompany(event) {
      this.getCollegues(event.target.value);
      this.validation = "";
      let tempCompany;
      for (var i = 0; i < this.companys.length; i++) {
        if (this.companys[i].name == event.target.value) {
          tempCompany = this.companys[i];
        }
      }
      this.licniProc = tempCompany.performanceEvaluation.licniProcenat;
      this.currentCompany = tempCompany;
      this.licniKpi = [];
      this.currentUser = '';
      this.lastEval = 0;
    },
    onChangeUser(event) {
      this.licniKpi = [];
      this.currentUser = '';
      this.lastEval = 0;
      this.getCollegues(this.currentCompany.name);
      this.validation = "";
      let tempUser;
      for (var i = 0; i < this.usersSameCompany.length; i++) {
        if (this.usersSameCompany[i].name == event.target.value) {
          tempUser = this.usersSameCompany[i];
        }
      }
      this.currentUser = tempUser;
      this.licniKpi = [];

      // if(this.currentUser.performanceEvaluation.length>0){
      this.lastEval = this.currentUser.performanceEvaluation.length - 1;
      //   this.evalEmpty=false;
      // }
      // if(!this.evalEmpty){
      if (this.currentUser.performanceEvaluation[this.lastEval].licniKpiTitle != undefined) {
        this.licniKpiTitle = this.currentUser.performanceEvaluation[this.lastEval].licniKpiTitle
      } else {
        this.licniKpiTitle = "Licni KPI"
      }


      for (i = 0; i < this.currentUser.performanceEvaluation[this.lastEval].licniKpi.length; i++) {
        this.licniKpi.push(this.currentUser.performanceEvaluation[this.lastEval].licniKpi[i]);
      }
      // }
    },
    ukloniLicni(i) {
      this.licniKpi.splice(i, 1);
    },
    increaseLicni() {
      let komp = {
        name: "",
        ponder: 0,
        comment: ""
      }
      this.licniKpi.push(komp);

    },
    submit() {

      this.validation = "";

      let kpiData = {
        licniKpiTitle: this.licniKpiTitle,
        licniKpi: this.licniKpi,
        userId: this.currentUser._id,
        lastEval: this.lastEval,
      }
      let zbirPonder = 0;
      for (var i = 0; i < this.licniKpi.length; i++) {
        zbirPonder += this.licniKpi[i].ponder;
      }
      if (zbirPonder != 100 && this.licniProc != 0) {
        this.validation = "Zbir pondera mora biti 100.";
      }
      if (this.validation == "") {
        this.setLicniKpi(kpiData);
        this.validation = `Sacuvani Licni Kpi za ${this.currentUser.name}`;
      }

    },
  },
  async created() {
    await this.getCompanies();
    await this.getAllUsers();
  },

}
</script>

<style lang="css" scoped>
.kpi {
  /* display: grid;
  grid-template-columns: 0.5fr 1.5fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 0px 0px; */
  /* grid-template-areas:
    ". ."
    ". ."
    ". ."
    ". ."; */
  background-color: #ddddff;
  border-style: solid;
  border-color: black;
  border-width: thin;
  border-radius: 5px;
  padding: 10px;
}

.validation {
  margin-top: 5px;
  background-color: #00aaaa;
}

.header {
  background-color: #ddffdd;
  color: black;
  border-style: solid;
  border-color: black;
  border-width: thin;
  border-radius: 5px;
  padding: 10px;
}
</style>
